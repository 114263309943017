<template>
	<div>

		<el-card class="filter-container" shadow="never">
			<el-form ref="form" :model="form" :rules="dataRule" label-width="80px">
				<el-form-item label="所属科室"  prop="did">
					<el-select v-model="form.did" placeholder="选择科室" style="width: 30%;">
						<el-option v-for="item in deptData" :key="item.deptNo" :label="item.deptName" :value="item.deptNo"></el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="医生姓名" prop="doctorName">
					<el-input v-model="form.doctorName" style="width: 30%;"></el-input>
				</el-form-item>
				
				<el-form-item label="医生职称" prop="level">
					<el-input v-model="form.level" style="width: 30%;"></el-input>
				</el-form-item>

                <el-form-item label="证书编号">
                    <el-input v-model="form.certificateNo" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="审批机关">
                    <el-input v-model="form.approvalAuthority" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="职业类别">
                    <el-input v-model="form.professionalCategory" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="职业范围">
                    <el-input v-model="form.professionalScope" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="职业地点">
                    <el-input v-model="form.professionalSite" style="width: 30%;"></el-input>
                </el-form-item>

                <el-form-item label="发证机关">
                    <el-input v-model="form.issuingAuthority" style="width: 30%;"></el-input>
                </el-form-item>

				<el-form-item label="医生性别">
					<el-radio-group v-model="form.sex">
						<el-radio label="男"></el-radio>
						<el-radio label="女"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="照片上传">
					<el-upload class="upload-demo" :action="uploadImgUrl" style="width: 30%;"
					 :on-success="onsuccess"  :on-remove="onremove" list-type="picture" :limit="1" :on-exceed="onexceed" :file-list="fileList"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">更新照片前，须先删除原照片</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="是否启用">
					<el-switch v-model="form.isShow"></el-switch>
				</el-form-item>
				
				<el-form-item label="排序">
                    <el-input v-model="form.sort" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
				</el-form-item>
				
				<el-form-item label="医生简介">
					<el-input  style="width: 30%;" v-model="form.doctorDesc"></el-input>
				</el-form-item>
				
				<el-form-item label="医生内容">
					<!-- <el-input  v-model="form.desc"></el-input> -->
					<div id="div1"></div>
				</el-form-item>
				
				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info" @click="gobreak">取消</el-button>
				</el-form-item>
			</el-form>
		</el-card>

	</div>


</template>
<script>
	import E from "wangeditor";
	export default {
		data() {
			return {
				form: {
					  "content":"",
					  "createTime": "",
					  "did": "",
					  "doctorDesc": "",
					  "doctorName": "",
					  "ghPrice": "",
					  "img": "",
					  "isShow": true,
					  "level": "",
					  "number": "",
					  "salt": "",
					  "sex": "",
					  "sort": "",
                      certificateNo:'',         // 职业证书编号
                      approvalAuthority: '',    // 审批机关
                      professionalCategory: '', // 职业类别
                      professionalScope: '',    // 职业范围
                      professionalSite: '',     // 职业地点
                      issuingAuthority: ''      // 发证机关
				},
				newsImg:"",
				query:[],
				deptData:[],
				fileList:[] ,
				uploadImgUrl: '', // 图片上传路径
                dataRule: {
                    did: [
                        { required: true, message: '所属科室不能为空', trigger: 'change' }
                    ],
                    doctorName: [
                        { required: true, message: '医生姓名不能为空', trigger: 'blur' }
                    ],
                    level: [
                        { required: true, message: '医生职称不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			// 点击删除
			onremove(file, fileList) {
				const that = this;
				that.newsImg='';
			},
			
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},
			
			onSubmit() {
				const that = this;
				let apiUrl = "";
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        that.form.isShow = that.form.isShow===true?1:0;
                        that.form.sex = that.form.sex=="男"?1:0;
                        // if(this.newsImg!==""){
                        // 	this.form.img = this.newsImg;
                        // }
                        that.form.img = that.newsImg;
                        if(this.query.ac=="edit"){
                            //修改逻辑
                            apiUrl = '/admin/doctor/edit';
                        }
                        else{
                            delete that.form.id;
                            //添加逻辑
                            apiUrl = '/admin/doctor/add';
                        }
                        that.api(apiUrl,that.form).then(res=>{
                            if(res.code==200){
                                that.$message({
                                    showClose:true,
                                    type:"success",
                                    message:"操作成功",
                                    duration:800,
                                    onClose(){
                                        // that.$router.push({name:"doctorlist"});
                                        that.$router.replace({name:'doctorlist'})
                                    }
                                })
                            }
                        });
                    }
                })
			},
			// 图片上传成功
			onsuccess(file, fileList) {
				const that = this;
				that.newsImg=file.data.filePath;
			},
			// 图片上传超出
			onexceed(e) {
				const that = this;
				this.$message({
					showClose: true,
					message: '数量超出限制',
					type: 'warning'
				});
			
			},
			//获取科室列表
			getdepts() {
				const that = this;
				that.api('/api/department/list',{isShow: '1', parentNo: '0'}).then(res => {
					that.deptData = res.data;
				})
			},
			getDoct(){
				const that = this;
				that.api('/admin/doctor/getEditInfo',{doctorId:that.query.id}).then(res => {
					
					that.form.did = res.data.deptNo;
					that.form.content = res.data.content;
					// that.form.deptName = res.data.deptName;
					that.form.doctorDesc = res.data.doctorDesc;
					that.form.doctorName = res.data.doctorName;
					that.form.doctorId = res.data.id;
					that.newsImg= res.data.img;
					that.form.isShow = res.data.isShow=="1"?true:false;
					that.form.level = res.data.level;
					that.form.sex = res.data.sex==1?"男":"女";
					that.form.sort = res.data.sort;
          that.form.certificateNo = res.data.certificateNo;
          that.form.approvalAuthority = res.data.approvalAuthority;
          that.form.professionalCategory = res.data.professionalCategory;
          that.form.professionalScope = res.data.professionalScope;
          that.form.professionalSite = res.data.professionalSite;
          that.form.issuingAuthority = res.data.issuingAuthority;

					if(res.data.img!=''){
						var imgshow={
							url:that.$utils.apiurl+res.data.img
						}
						
						that.fileList.push(imgshow)
					}
					that.editor.txt.html(that.form.content);	
					
					
					
				})
			}
			
		},
		created(){
			const that = this;
			this.query = this.$route.query;
			that.uploadImgUrl = that.$utils.uploadimgurl;
			this.getdepts();
			if(this.query.id){
				this.getDoct();
			}
		},
		mounted() {
			const that = this;
			that.editor = new E("#div1");
			that.editor.config.onchange = function(newHtml) {
				that.form.content = newHtml;
			};
			that.editor.config.uploadImgMaxLength = 1;
			// that.editor.config.uploadImgServer = "http://bzjj.hrbcft.com/api/index/up1";
			// that.editor.config.uploadFileName = "upfile";
			// 接口地址 key
			that.editor.config.uploadImgServer = that.$utils.uploadimgurl;
			that.editor.config.uploadFileName = "file";
			
			that.editor.config.uploadImgHooks = {
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					var img = that.$utils.apiurl + result.data.filePath
					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					insertImgFn(img)
				}
			}
			that.editor.create();
		},
		
		beforeDestroy() {
			const that=this;
			// 销毁编辑器
			that.editor.destroy()
			that.editor = null
		},
	}
</script>

<style scoped lang="less">

</style>

